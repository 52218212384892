<template>
  <div class="container_body">
    <div class="container_box">
      <div class="left">
        <el-carousel height="450px">
          <el-carousel-item v-for="item in 4" :key="item" >
            <div class="slide_item">
              <img :src="require(`@/assets/images/product/p${item}.jpg`)" alt="">
            </div>
          </el-carousel-item>
        </el-carousel>
        <div class="notice_box swiper" id="swiper_notice"> 
            <div class="swiper-wrapper">
              <div class="swiper-slide " v-for="(item,index) in swipeData" :key="index">

                <span style="color: #333;">{{ item.city }}</span>
                <span style="color: #999;"> {{ item.name }}</span>
                <span style="color: #d00;"> {{ item.phone }}</span>
                <span style="color: #f90;"> {{ item.info }}</span>
              </div>
             
            </div>
        </div>
      </div>
      <div class="right">
        <h6>
          <span style="color:#f44">商品名称</span>厂家直供 实力企业 包安装运输
        </h6>
        <ul class="tags">
          <li>专业团队</li>
          <li>经验丰富</li>
          <li>厂家直供</li>
          <li>源头低价</li>
        </ul>
        <div class="info_content">
          <div class="p1">
            覆盖区域 <span style="color:#f44">{{ city }}</span>&nbsp;&nbsp; 安装维修调试，售后无忧
          </div>
          <div class="p1">
            发货期限 <span style="color:#06b">现货</span>&nbsp;&nbsp; 厂家现货直发
          </div>
          <div class="p3">
            <p>服务保障 </p>
            <span>厂</span>厂家直供
            <span>价</span>源头低价
            <span>保</span>质量保障
            <span>装</span>安装运输
            <span>现</span>现货直发
          </div>
        </div>
        <div class="act_box">
          <p>[{{ month }}月{{ day }}日] 网络咨询免费报价,名额还剩:{{residue}}名！</p>
          <i @click="openDialog">立即获取名额</i>
        </div>
        <div class="foo_box">
          今日已有 
          <span style="color:#f44">{{ number }}</span>
          人浏览咨询； 选择规格参数
          <span style="color:#f44;cursor: pointer;" @click="openDialog">免费获取报价</span>
        </div>
        <div class="prar_box">
          <div class="prar_item">
            <div class="label">产品类型</div>
            <div class="value">
              <ul>
                <li :class="{'active':active1===item.value}" 
                v-for="(item,index) in productTypeMap.list1" 
                :key="index" @click="handleClick1(item)">{{ item.label }}</li>
                
              </ul>
            </div>
          </div>
          <div class="prar_item">
            <div class="label">产品材质</div>
            <div class="value">
              <ul>
                <li :class="{'active':active2===item.value}" 
                v-for="(item,index) in productTypeMap.list2" 
                :key="index" @click="handleClick2(item)">{{ item.label }}</li>
              </ul>
            </div>
          </div>
          <div class="prar_item">
            <div class="label">使用场景</div>
            <div class="value">
              <ul>
                <li :class="{'active':active3===item.value}" 
                v-for="(item,index) in productTypeMap.list3" 
                :key="index" @click="handleClick3(item)">{{ item.label }}</li>
              </ul>
            </div>
          </div>
          <div class="prar_item">
            <div class="label">获取报价</div>
            <div class="value">
              <div class="inp_box">
                <input type="text" v-model="phone" placeholder="请输入手机号">
                <div class="btn" @click="submitData({cuPhone:phone})">免费获取报价</div>
              </div>
              <p>报价单含各产品价格及参数,方便您做对比决策</p>
            </div>
          </div>
          <div class="btn_box">
            <a href="javascript:;" class="btn1" @click="openDialog">咨询选型客服</a>
            <a href="javascript:;" class="btn2" @click="openDialog">了解今日价格</a>
          </div>
          <div class="link_box">
            <span @click="openDialog">规格参数</span>
            <span @click="openDialog">标准出场配置</span>
            <span @click="openDialog">周边项目案例</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mixins } from "@/mixin/index";

import Swiper from 'swiper';
export default {
  mixins: [mixins],
  components: {
  },
  data() {
    return {
      phone:'',
    };
  },
  mounted() {
    this.initSwiper()
    
  },
  methods: {
    initSwiper(){
      var mySwiper = new Swiper('#swiper_notice', {
          autoplay: true,//可选选项，自动滑动
          loop:true, // 是否循环滚动
          direction: 'vertical',   //滑动方向默认是垂直
          slidesPerView:2,//每次展示两条
        })
    },
    openDialog(){
      this.$emit('openDialog')
    },
  },
 
};
</script>

<style scoped lang="less">
.container_body{
  background: #fff;
  width: 100%;
  margin-top: 20px;
  .container_box{
    width: 1190px;
    margin: 0 auto;
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
    .left{
      width: 450px;
      .slide_item{
        width: 100%;
        height: 100%;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .notice_box{
        margin-top: 10px;
        height: 60px;
        overflow: hidden;

        /deep/.swiper-slide{
          height: 30px !important;
          font-size: 14px;
          line-height: 30px;

        }
      }
      
    }
    .right{
      width: 700px;
      h6{
        font-size: 24px;
        color: #333;
        font-weight: normal;
      }
      .tags{
        margin-top: 10px;
        display: flex;
        li{
          color: #f44;
          padding: 4px 6px;
          border: 1px solid #f44;
          border-radius: 2px;
          font-size: 12px;
          font-weight: bold;
          margin-right: 6px;
        }
      }
      .info_content{
        padding: 10px 15px;
        background: #f6f6f6;
        color: #999;
        font-size: 14px;
        margin-top: 15px;
        .p1{
          margin-bottom: 10px;
        }
        .p3{
          color: #333;
          display: flex;
          p{
            color: #999;
            margin-right: 6px;
          }
          span{
            display: inline-block;
            background: #06a;
            padding:0 2px;
            border-radius: 2px;
            color: #fff;
            margin:0 4px;
          }
        }
      }
      .act_box{
        width: 100%;
        height: 40px;
        background: #e44;
        display: flex;
        align-items: center;
        padding: 0 15px;
        p{
          color: #fff;
        }
        i{
          color: #ff0;
          font-weight: bold;
          text-decoration:underline;
          cursor: pointer;
          font-style: normal;
        }
      }
      .foo_box{
        margin-top: 15px;
        color: #999;
      }
      .prar_box{
        width: 100%;
        margin-top: 15px;
        .prar_item{
          display: flex;
          margin-bottom: 10px;
          .label{
            margin-right: 15px;
            font-weight: bold;
            color: #666;
            width: 70px;
          }
          .value{
            flex: 1;
            ul{
              display: flex;
              flex-wrap: wrap;
              li{
                user-select: none;
                margin: 0 5px 5px 0;
                padding: 2px 8px;
                background: #fbfbfb;
                border: solid 1px #e2e2e2;
                border-radius: 2px;
                cursor: pointer;
                opacity: 0.9;
                font-size: 13px;
                &.active{
                  background: #f44;
                  color: #fff;
                }
              }
            }
            .inp_box{
              display: flex;
              input{
                max-width: 200px;
                height: 32px;
                padding: 0 10px;
                border: solid 1px #f44;
                flex: 1;
                font-size: 14px;
                outline: none;
              }
              .btn{
                width: 110px;
                line-height: 32px;
                background: #f44;
                color: #fff;
                font-size: 14px;
                font-weight: bold;
                text-align: center;
                cursor: pointer;
              }
            }
            p{
              font-size: 12px;
              color: #999;
              margin-top: 6px;
            }
          }
        }
        .btn_box{
          padding: 20px 0 0;
          border-top: dashed 1px #e2e2e2;
          display: flex;
          a{
            line-height: 20px;
            margin-right: 15px;
            padding: 12px 40px;
            border: solid 1px;
            border-radius: 3px;
            color: #fff;
            font-size: 16px;
            float: left;
            opacity: 0.9;
            text-decoration: none;
            user-select: none;
            tap-highlight-color: transparent;
            &.btn1{
              background: #f44;
              border-color: #f44;
            }
            &.btn2{
              background: #0af;
              border-color: #0af;
            }
          }
          
        }
        .link_box{
          color: #999;
          display: flex;
          margin-top: 20px;
          span{
            display: inline-block;
            margin-right: 20px;
            cursor: pointer;
            user-select: none;
            &:hover{
              color: #666;
            }
          }
          
        }
      }
    }
  }
}
</style>