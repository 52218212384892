// 主体容器
<template>
  <div class="container-fluid">
    <header-index></header-index>
    <div class="body">
      <body-index></body-index>
      <img-body></img-body>
      <form-index></form-index>

      <div class="last_swiper">
        <div class="notice_box swiper" id="last_swiper">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, index) in swipeData"
              :key="index"
            >
              <span style="color: #333">{{ item.city }}</span>
              <span style="color: #999"> {{ item.name }}</span>
              <span style="color: #d00"> {{ item.phone }}</span>
              <span style="color: #f90"> {{ item.info }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <p>
          公司名称为您提供产品的详细参数，实时报价，实拍图片，产品细节，售后服务等全方位信息
        </p>
        <p>
          公司名称为您提供产品的详细参数，实时报价，实拍图片，产品细节，售后服务等全方位信息
        </p>
      </div>
      <Dialog ref="DialogChild"></Dialog>
    </div>
    <div class="inp_tool" v-if="showTool">
      <div class="inp_tel">
        <input type="text" placeholder="请输入手机号" />
        <div class="btn">免费获取报价</div>
      </div>
      <p class="tip">
        报价单含各产品价格及参数,今日已有
        <span style="color: #f44">{{ number }}</span>
        人浏览咨询
      </p>
    </div>
    <div class="foot_tool">
      <a href="" class="btn mes">
        <i class="icon"></i>
        <em>留言询价</em>
      </a>
      <a href="" class="btn sms">
        <i class="icon"></i>
        <em>短信询价</em>
      </a>
      <a href="" class="btn tel">
        <i class="icon"></i>
        <em>拨打电话</em>
      </a>
    </div>
  </div>
</template>

<script>
import HeaderIndex from "@/pages/m_home/components/HeaderIndex.vue";
import BodyIndex from "@/pages/m_home/components/BodyIndex.vue";
import ImgBody from "@/pages/m_home/components/ImgBody.vue";
import FormIndex from "@/pages/m_home/components/FormIndex.vue";
import Dialog from "@/components/dialog.vue";
import { swipeData } from "@/utils/mockData";
import Swiper from "swiper";
import { mixins } from "@/mixin/index";
export default {
  mixins: [mixins],
  components: {
    HeaderIndex,
    BodyIndex,
    ImgBody,
    FormIndex,
    Dialog,
  },
  data() {
    return {
      showTool: false,
      swipeData,
      swiperOption: {
        direction: "vertical", //滑动方向默认是水平
        slidesPerView: 2,
        loop: true, // 是否循环滚动
        //自动播放
        autoplay: {
          delay: 1000,
        },
      },
    };
  },
  mounted() {
    this.initSwiper();
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    initSwiper() {
      var mySwiper = new Swiper("#last_swiper", {
        autoplay: true, //可选选项，自动滑动
        loop: true, // 是否循环滚动
        direction: "vertical", //滑动方向默认是垂直
        slidesPerView: 3, //每次展示两条
      });
    },
    openDialog() {
      this.$emit("openDialog");
    },
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      let offsetTop = document.querySelector("#searchBar").offsetTop;
      console.log(scrollTop, "元素：", offsetTop);
      if (scrollTop > offsetTop) {
        this.showTool = true;
      } else {
        this.showTool = false;
      }
    },
  },
};
</script>

<style scoped lang="less">
a,
a:link,
a:visited {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  user-select: none;
}
a {
  color: rgba(0, 0, 0, 0.9);
  text-decoration: none;
}
.container-fluid {
  width: 100%;
  background: #f6f6f6;
  position: relative;
  padding-bottom: 100px;
  .body {
    width: 100%;
    overflow: hidden;
    background: #fff;
    .last_swiper {
      padding: 10px 12px;
      margin-top: 10px;
      background: #fff;
      .notice_box {
        overflow: hidden;
        position: relative;
        height: 90px;
        top: 0;
        /deep/.swiper-slide {
          height: 30px !important;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          word-wrap: break-word;
          word-break: break-all;
          white-space: normal !important;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          line-height: 30px;
          font-size: 12px;
        }
      }
    }
    .footer {
      background-color: #f5f5f5;
    }
  }
  .inp_tool {
    position: fixed;
    box-shadow: 0px 0px 7px #333;
    left: 0;
    top: 0;
    z-index: 9999;
    width: 100%;
    padding: 5px 10px;
    background: #fff;
    .inp_tel {
      display: flex;
      padding: 5px 0 0;
      input {
        width: 70%;
        height: 32px;
        padding: 0 5px;
        border: solid 1px #0af;
        float: left;
        color: #222;
        font-size: 14px;
        outline: none;
        font-family: "microsoft yahei", Verdana, Arial, Helvetica, sans-serif;
        box-shadow: none;
      }
      .btn {
        width: 30%;
        line-height: 32px;
        background: #0af;
        color: #fff;
        text-align: center;
        font-size: 14px;
        cursor: pointer;
        text-decoration: none;
      }
    }
    .tip {
      margin-top: 6px;
      color: #999 !important;
      font-size: 12px;
    }
  }
  .foot_tool {
    box-shadow: 0px 0px 5px #999;
    width: 100%;
    padding: 0px 0 0px;
    background: #fff;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 999999;
    display: flex;
    justify-content: space-around;
    align-items: center;
    &::before {
      width: 100%;
      height: 1px;
      left: 0;
      top: 0;
      transform: scaleY(0.5);
      content: "";
      background: #ddd;
      position: absolute;
      z-index: 2;
    }
    .btn {
      min-width: 33.3%;
      line-height: 42px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      .icon {
        width: 20px;
        height: 20px;
        margin-right: 5px;
        line-height: 0;
        background: url(../../assets/icon.png) no-repeat;
        font-size: 0;
        text-indent: -99999px;
        display: inline-block;
        vertical-align: top;
      }
      em {
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        word-break: break-all;
        white-space: normal !important;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        font-size: 14px;
        font-style: normal;
      }
    }
    .sms {
      background: linear-gradient(to right, #fb0 0%, #f80 100%);
      .icon {
        background-position: -80px -60px;
        background-size: 100px;
      }
    }
    .mes {
      background: linear-gradient(to right, #0af 0%, #07f 100%);
      .icon {
        background-position: -60px -60px;
        background-size: 100px;
      }
    }
    .tel {
      background: linear-gradient(to right, #f70 0%, #f40 100%);
      .icon {
        background-position: -40px -60px;
        background-size: 100px;
      }
    }
  }
}
</style>