<template>
  <div>
    <div class="container_body">
      <div class="foo_box">
        今日已有
        <span style="color: #f44">{{ number }}</span>
        人浏览咨询； 选择规格参数
        <span style="color: #f44; cursor: pointer" @click="openDialog"
          >免费获取报价</span
        >
      </div>
      <div class="prar_box">
        <div class="prar_item">
          <div class="label">产品类型</div>
          <div class="value">
            <ul>
              <li
                :class="{ active: active1 === item.value }"
                v-for="(item, index) in productTypeMap.list1"
                :key="index"
                @click="handleClick1(item)"
              >
                {{ item.label }}
              </li>
            </ul>
          </div>
        </div>
        <div class="prar_item">
          <div class="label">产品材质</div>
          <div class="value">
            <ul>
              <li
                :class="{ active: active2 === item.value }"
                v-for="(item, index) in productTypeMap.list2"
                :key="index"
                @click="handleClick2(item)"
              >
                {{ item.label }}
              </li>
            </ul>
          </div>
        </div>
        <div class="prar_item">
          <div class="label">使用场景</div>
          <div class="value">
            <ul>
              <li
                :class="{ active: active3 === item.value }"
                v-for="(item, index) in productTypeMap.list3"
                :key="index"
                @click="handleClick3(item)"
              >
                {{ item.label }}
              </li>
            </ul>
          </div>
        </div>
        <div class="prar_item">
          <div class="label">获取报价</div>
          <div class="value">
            <div class="inp_box">
              <input type="text" placeholder="请输入手机号" />
              <div class="btn">免费获取报价</div>
            </div>
            <p>报价单含各产品价格及参数,方便您做对比决策</p>
          </div>
        </div>
      </div>
    </div>
    <div class="dit_card" style="position: relative">
      <div class="btn_box">
        <a href="javascript:;" class="btn1" @click="openDialog">
          <i class="icon"></i>
          <b>咨询选型客服</b>
        </a>
        <a href="javascript:;" class="btn2" @click="openDialog">
          <i class="icon"></i>
          <b>了解今日价格</b>
        </a>
      </div>
      <div class="link_box">
        <a href="javascript:;" class="btn1" @click="openDialog">
          <i class="icon"></i>
          <b>规格参数</b>
        </a>
        <a href="javascript:;" class="btn2" @click="openDialog">
          <i class="icon"></i>
          <b>标准出场配置</b>
        </a>
        <a href="javascript:;" class="btn3" @click="openDialog">
          <i class="icon"></i>
          <b>周边项目案例</b>
        </a>
      </div>
    </div>

    <div class="bc_swiper">
      <div class="notice_box swiper" id="before_city">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, index) in swipeData"
            :key="index"
          >
            <span style="color: #333">{{ item.city }}</span>
            <span style="color: #999"> {{ item.name }}</span>
            <span style="color: #d00"> {{ item.phone }}</span>
            <span style="color: #f90"> {{ item.info }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="info">
      <div class="p1">
        覆盖区域： <span style="color: #0b8">浙江省宁波市</span>&nbsp;&nbsp;
      </div>
      <div class="p1">
        免费报价：<span style="color: #f44">
          [{{ month }}月{{ day }}日] 网络咨询免费报价,名额还剩:{{
            residue
          }}名！</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mixins } from "@/mixin/index";
import { swipeData } from "@/utils/mockData";
import Swiper from "swiper";
export default {
  mixins: [mixins],
  components: {},
  data() {
    return {
      swipeData,
      swiperOption: {
        direction: "vertical", //滑动方向默认是水平
        slidesPerView: 2,
        loop: true, // 是否循环滚动
        //自动播放
        autoplay: {
          delay: 1000,
        },
      },
    };
  },
  mounted() {
    this.initSwiper();
  },
  methods: {
    initSwiper() {
      var mySwiper = new Swiper("#before_city", {
        autoplay: true, //可选选项，自动滑动
        loop: true, // 是否循环滚动
        direction: "vertical", //滑动方向默认是垂直
        slidesPerView: 2, //每次展示两条
      });
    },
    openDialog() {
      this.$emit("openDialog");
    },
  },
};
</script>

<style scoped lang="less">
.container_body {
  line-height: 20px;
  background: #f6f6f6;
  padding: 12px 12px 0;
  font-size: 12px;
  .foo_box {
    padding: 0px 0 8px;
    font-size: 13px;
    color: #999;
  }
  .prar_box {
    width: 100%;
    margin-top: 10px;
    .prar_item {
      display: flex;
      margin-bottom: 10px;
      .label {
        padding-top: 2px;
        font-weight: bold;
        color: #666;
        width: 60px;
      }
      .value {
        flex: 1;
        ul {
          display: flex;
          flex-wrap: wrap;
          li {
            user-select: none;
            margin: 0 5px 5px 0;
            padding: 1px 8px;
            background: #fbfbfb;
            border: solid 1px #e2e2e2;
            border-radius: 2px;
            cursor: pointer;
            opacity: 0.9;
            font-size: 12px;
            &.active {
              background: #f44;
              color: #fff;
            }
          }
        }
        .inp_box {
          display: flex;
          input {
            max-width: 140px;
            height: 32px;
            padding: 0 10px;
            border: solid 1px #f44;
            flex: 1;
            font-size: 14px;
            outline: none;
          }
          input::placeholder {
            /* placeholder颜色  */
            color: #bbbbbb;
          }
          .btn {
            width: 90px;
            line-height: 32px;
            background: #f44;
            color: #fff;
            font-size: 14px;
            font-weight: bold;
            text-align: center;
            cursor: pointer;
          }
        }
        p {
          font-size: smaller !important;
          color: #999;
          margin-top: 6px;
        }
      }
    }
  }
}
.dit_card {
  background-color: #fff;
  padding: 15px 12px;
  border-top: dashed 1px #e2e2e2;
  .btn_box {
    display: flex;
    justify-content: space-between;
    a {
      width: 48%;
      line-height: 32px;
      margin: 0;
      padding: 0px;
      border-radius: 16px;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: solid 1px;
      color: #fff;
      opacity: 0.9;
      -webkit-tap-highlight-color: transparent;
      -webkit-user-select: none;
      text-decoration: none;
      b {
        font-weight: normal;
      }
      .icon {
        width: 15px;
        height: 15px;
        margin-right: 6px;
        line-height: 0;
        background: url(../../../assets/icon.png) no-repeat;
        font-size: 0;
        text-indent: -99999px;
        display: inline-block;
        vertical-align: top;
      }
      &.btn1 {
        background: #f44;
        border-color: #f44;
        .icon {
          background-position: -15px 0;
          background-size: 150px;
        }
      }
      &.btn2 {
        background: #0af;
        border-color: #0af;
        .icon {
          background-position: -45px 0;
          background-size: 150px;
        }
      }
    }
  }
  .link_box {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
    a {
      margin: 0px;
      line-height: 20px;
      opacity: 0.8;
      -webkit-tap-highlight-color: transparent;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      color: rgba(0, 0, 0, 0.9);
      text-decoration: none;
      b {
        font-weight: normal;
        color: #777;
      }
      .icon {
        width: 16px;
        height: 16px;
        margin: 2px 5px 0 0;
        opacity: 0.7;
        line-height: 0;
        background: url(../../../assets/icon.png) no-repeat;
        font-size: 0;
        text-indent: -99999px;
        display: inline-block;
        vertical-align: top;
      }
      &.btn1 {
        .icon {
          background-position: 0px -80px;
          background-size: 100px;
        }
      }
      &.btn2 {
        .icon {
          background-position: -20px -80px;
          background-size: 100px;
        }
      }
      &.btn3 {
        .icon {
          background-position: -40px -80px;
          background-size: 100px;
        }
      }
    }
  }
}
.bc_swiper {
  background: #fff;
  padding: 10px 12px;
  margin-top: 10px;
  .notice_box {
    height: 60px;
    overflow: hidden;
    position: relative;
    top: 0;
    /deep/.swiper-slide {
      height: 30px !important;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
      word-break: break-all;
      white-space: normal !important;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      line-height: 30px;
      font-size: 12px;
    }
  }
}
.info {
  padding: 6px 12px;
  font-size: 12px;
  color: #999;
  background: #f8f8f8;
  .p1 {
    padding: 5px 0;
  }
}
</style>