// 主体容器
<template>
  <div class="container">
      <header-index @openDialog="openDialog"></header-index>
      <body-index @openDialog="openDialog"></body-index>
      <Details ></Details>
      <footer-index></footer-index>
      <Dialog ref="DialogChild"></Dialog>
  </div>
</template>

<script>
import BodyIndex from "@/pages/pc_home/components/BodyIndex.vue";
import FooterIndex from "@/pages/pc_home/components/FooterIndex.vue";
import Details from "@/pages/pc_home/components/Details.vue";
import HeaderIndex from "@/pages/pc_home/components/HeaderIndex.vue";
import Dialog from "@/components/dialog.vue";
import { mixins } from "@/mixin/index";
export default {
  mixins: [mixins],
  components: {
    HeaderIndex,
    BodyIndex,
    Details,
    FooterIndex,
    Dialog,
  },
  data() {
    return {
    };
  },
  methods: {
    openDialog(){
      this.$refs.DialogChild.open()
    },
  }
};
</script>

<style scoped lang="less">
.container{
  width: 100%;
  background: #f6f6f6;
  position: relative;
}
</style>