<template>
  <div id="app" style="position: relative">
    <router-view />
  </div>
</template>

<script>
import { isMobile } from "@/utils/index";
export default {
  name: "App",
  created() {
},
  mounted() {
    if (isMobile(window)) {
      this.$router.replace("/m_home");
    } else {
      this.$router.replace("/pc_home");
    }
  },
  methods: {
    
  }
};
</script>

<style scoped lang="less">

</style>
