
export const swipeData = [
  {
    city:'河北保定',
    name:'张先生',
    phone:'159****0317',
    info:'项目采购铝艺大门，浏览咨询该页面',
  },
  {
    city:'山西运城',
    name:'林先生',
    phone:'155****6612',
    info:'项目采购铝艺大门，浏览咨询该页面',
  },
  {
    city:'北京',
    name:'李女士',
    phone:'133****0006',
    info:'项目采购铝艺大门，浏览咨询该页面',
  },
  {
    city:'河南郑州',
    name:'邹先生',
    phone:'159****5147',
    info:'项目采购铝艺大门，浏览咨询该页面',
  },
  {
    city:'浙江宁波',
    name:'王先生',
    phone:'168****0311',
    info:'项目采购铝艺大门，浏览咨询该页面',
  },
  {
    city:'河北保定',
    name:'张先生',
    phone:'159****0317',
    info:'项目采购铝艺大门，浏览咨询该页面',
  },
  {
    city:'山西运城',
    name:'林先生',
    phone:'155****6612',
    info:'项目采购铝艺大门，浏览咨询该页面',
  },
  {
    city:'北京',
    name:'李女士',
    phone:'133****0006',
    info:'项目采购铝艺大门，浏览咨询该页面',
  },
  {
    city:'河南郑州',
    name:'邹先生',
    phone:'159****5147',
    info:'项目采购铝艺大门，浏览咨询该页面',
  },
  {
    city:'浙江宁波',
    name:'王先生',
    phone:'168****0311',
    info:'项目采购铝艺大门，浏览咨询该页面',
  },
  {
    city:'河北保定',
    name:'张先生',
    phone:'159****0317',
    info:'项目采购铝艺大门，浏览咨询该页面',
  },
  {
    city:'山西运城',
    name:'林先生',
    phone:'155****6612',
    info:'项目采购铝艺大门，浏览咨询该页面',
  },
  {
    city:'北京',
    name:'李女士',
    phone:'133****0006',
    info:'项目采购铝艺大门，浏览咨询该页面',
  },
  {
    city:'河南郑州',
    name:'邹先生',
    phone:'159****5147',
    info:'项目采购铝艺大门，浏览咨询该页面',
  },
  {
    city:'浙江宁波',
    name:'王先生',
    phone:'168****0311',
    info:'项目采购铝艺大门，浏览咨询该页面',
  }
]
export const productTypeMap = {
  list1:[
    {label:'参数1',value:'001'},
    {label:'参数2',value:'002'},
    {label:'参数3',value:'003'},
    {label:'参数4',value:'004'},
    {label:'参数5',value:'005'},
    {label:'参数6',value:'006'},
    {label:'参数7',value:'007'},
  ],
  list2:[
    {label:'参数1',value:'001'},
    {label:'参数2',value:'002'},
    {label:'参数3',value:'003'},
    {label:'参数4',value:'004'},
    {label:'参数5',value:'005'},
    {label:'参数6',value:'006'},
    {label:'参数7',value:'007'},
  ],
  list3:[
    {label:'参数1',value:'001'},
    {label:'参数2',value:'002'},
    {label:'参数3',value:'003'},
    {label:'参数4',value:'004'},
    {label:'参数5',value:'005'},
    {label:'参数6',value:'006'},
    {label:'参数7',value:'007'},
  ]
}