<template>
  <div class="details_body">
    <div class="content">
      <div class="left">
        <h5 class="title">热卖产品</h5>
        <div class="img_body">
          <ul>
            <li>
              <div class="img_box">
                <img src="@/assets/images/product/p1.jpg" alt="">
              </div>
              <div class="names">产品名称</div>
            </li>
            <li>
              <div class="img_box">
                <img src="@/assets/images/product/p1.jpg" alt="">
              </div>
              <div class="names">产品名称</div>
            </li>
            <li>
              <div class="img_box">
                <img src="@/assets/images/product/p1.jpg" alt="">
              </div>
              <div class="names">产品名称</div>
            </li>
          </ul>
        </div>
      </div>
      <div class="right">
        <h5  class="title">产品详情</h5>
        <div class="content_body">
            <div class="act_box">
              <div class="left_item">
                <p style="margin-bottom:20px">问：2023年9月铝艺大门价格/多少钱？</p>
                <p>答：因材料、运输等价格波动，请填写手机号获取今日报价表</p>
              </div>
              <div class="right_item">
                <div class="inp_box">
                  <input type="text" v-model="phone" placeholder="请输入手机号">
                  <a href="javascript:;" @click="submitData({cuPhone:phone})">免费获取报价</a>
                </div>
                <p>报价单含各产品价格及参数,方便您做对比决策。</p>
              </div>
            </div>
            <div class="info_box">
                <div class="lines">
                    <p>
                      <span class="label">货号</span>
                      <span class="value">ZNGC-43460</span>
                    </p>
                    <p>
                      <span class="label">货号</span>
                      <span class="value">ZNGC-43460</span>
                    </p>
                    <p>
                      <span class="label">货号</span>
                      <span class="value">ZNGC-43460</span>
                    </p>
                </div>
                <div class="lines">
                    <p>
                      <span class="label">货号</span>
                      <span class="value">ZNGC-43460</span>
                    </p>
                    <p>
                      <span class="label">货号</span>
                      <span class="value">ZNGC-43460</span>
                    </p>
                    <p>
                      <span class="label">货号</span>
                      <span class="value">ZNGC-43460</span>
                    </p>
                </div>
            </div>
            <div class="img_body">
              <img src="@/assets/images/product/p1.jpg" alt="">
              <img src="@/assets/images/details/d1.jpg" alt="">
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mixins } from "@/mixin/index";
export default {
  mixins: [mixins],
  data() {
      return {
        phone:'',
      };
    },
};
</script>

<style scoped lang="less">
  .details_body{
    width: 100%;
    .content{
      width: 1190px;
      margin: 0 auto;
      display: flex;
      padding-top: 20px;
      justify-content: space-between;
      .title{
        font-size: 20px;
        font-weight: bold;
      }
      
      .left{
        width: 280px;
        .img_body{
          background: #fff;
          padding: 20px;
          margin-top: 20px;
          li{
            margin-bottom: 30px;
            .img_box{
              width: 240px;
              height: 240px;
              img{
                width: 100%;
                height: 100%;
              }
            }
            .names{
              padding: 10px 0 0px;
              font-size: 15px;
              font-weight: bold;
            }
          }
        }
      }
      .right{
        width: 880px;
        .content_body{
          background: #fff;
          width: 100%;
          margin-top: 20px;
          .act_box{
            width: 100%;
            background: #0af;
            padding: 20px;
            padding-right: 60px;
            display: flex;
            justify-content: space-between;
            .left_item{
              color: #fff;

            }
            .right_item{
              .inp_box{
                display: flex;
                input{
                  width: 190px;
                  height: 36px;
                  padding: 0 10px;
                  border: none;
                  font-size: 15px;
                  outline: none;
                }
                a{
                  line-height: 36px;
                  padding: 0 15px;
                  background: #fe3;
                  color: #f44;
                  font-size: 15px;
                  user-select: none;
                  text-decoration: none;
                }
              }
              p{
                font-size: 12px;
                margin-top: 10px;
                color: #fff;
                opacity: 0.8;
              }
            }
          }
          .info_box{
            padding: 30px;
            .lines{
              display: flex;
              margin-bottom: 15px;
              p{
                display: flex;
                width: 33%;
                .label{
                  font-size: 13px;
                  width: 50px;
                  color: #999;
                }
                .value{
                  font-size: 13px;
                  color: #666;
                }
              }
            }
          }
          .img_body{
            padding: 0 30px;
            img{
              width: 100%;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
</style>