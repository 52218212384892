<template>
  <div class="foot_content">
    <div class="content">
      <div class="info_box">
        <h6>源头低价 获取出厂配置报价单</h6>
        <p>3分钟急速报价，您将收到回电，请保持电话畅通</p>
        <div class="card_box">
          <div class="item">
            <span>厂家直供</span>
            <span>质量保障</span>
          </div>
          <div class="item">
            <span>选型工程师</span>
            <span>一对一服务</span>
          </div>
          <div class="item">
            <span>现货直发</span>
            <span>包安装验收</span>
          </div>
        </div>
        <div class="info">今日已有<span style="color:#ff0">{{ number }}</span> 人浏览咨询</div>
        <div class="info">免费咨询客服 ，3分钟急速报价</div>
      </div>
      <div class="form_box">
        <h5>免费获取出厂配置报价单</h5>
        <div class="form_content">
          <el-input  placeholder="您的称呼" v-model="formData.cuName" prefix-icon="el-icon-user" style="margin-top: 20px;"></el-input>
          <el-input  placeholder="输入您的手机号" v-model="formData.cuPhone" prefix-icon="el-icon-phone-outline" style="margin-top: 20px;"></el-input>
          <el-input type="textarea" style="margin-top: 20px;" v-model="formData.cuRemark"
          :rows="5" placeholder="详细需求（选填），如您关心的日常运维成本，产品价格，质量，外观，售后服务要求等等..."></el-input>
          <a class="btn" href="javascript:;" @click="submitData(formData)">提交需求，免费获取报价单</a>
        </div>
      </div>
      
    </div>
    <div class="footer">
      <p>公司名称为您提供产品的详细参数，实时报价，实拍图片，产品细节，售后服务等全方位信息</p>
      <p>公司名称为您提供产品的详细参数，实时报价，实拍图片，产品细节，售后服务等全方位信息</p>
    </div>
  </div>
</template>

<script>
import { mixins } from "@/mixin/index";
export default {
  mixins: [mixins],
};
</script>

<style scoped lang="less">
.foot_content{
  width: 100%;
  margin-top: 50px;
  .content{
    width: 1190px;
    margin: 0 auto;
    background: rgba(204,51,51,0.9);
    border-radius: 5px;
    position: relative;
    padding: 30px;
    .info_box{
      width: 600px;
      color: #fff;
      h6{
        font-size: 36px;
        text-align: center;

      }
      p{
        margin-top: 15px;
        font-size: 20px;
        text-align: center;
      }
      .card_box{
        margin-top: 50px;
        display: flex;
        justify-content: space-around;
        .item{
          line-height: 1.8em;
          padding: 12px 20px;
          border: solid 1px #fff;
          font-size: 18px;
          font-weight: bold;
          color: #fff;
          text-align: center;
          span{
            text-align: center;
            display: block;
          }
        }
      }
      .info{
        font-size: 22px;
        text-align: center;
        margin-top: 20px;
      }
    }
    .form_box{
      width: 435px;
      background: #fff;
      border-radius: 5px;
      overflow: hidden;
      position: absolute;
      top: -10%;
      right: 8%;
      h5{
        width: 100%;
        text-align: center;
        padding: 12px 0;
        background: #c33;
        color: #fff;
        font-size: 24px;
        font-weight: normal;
      }
      .form_content{
        padding: 30px 50px;
        .btn{
          margin-top: 30px;
          display: block;
          background: #c33;
          width: 100%;
          line-height: 40px;
          padding: 3px 0;
          color: #fff;
          text-align: center;
          font-size: 16px;
          opacity: 0.9;
          user-select: none;
          text-decoration: none;
        }
      }
    }
  }
  .footer{
    width: 100%;
    background: #fff;
    padding: 30px;
    margin-top: 50px;
    p{
      text-align: center;
      color: #666;
      margin-bottom: 15px;
    }
  }
}
</style>