<template>
  <div class="dialog_content">
    <el-dialog
      :visible.sync="dialogVisible"
      width="360px"
      :show-close="false"
      :before-close="handleClose">
      <div class="dialog_body">
        <div class="col_box">
          <h3>今日报价</h3>
          <p >{{ month }}月{{ day }}日出场配置报价单</p>
          <i class="el-icon-close close" @click="handleClose"></i>
        </div>
        <div class="inp_box">
          <input type="text" v-model="phone" placeholder="请输入您的电话">
          <a href="javascript:;" @click="submitData({cuPhone:phone})">免费通话</a>
        </div>
        <div class="info_box">
          <div class="line">
            <p>请输入 <span style="color:#f30">您的电话号码</span> ，企业将自动回拨给您</p>
            <p>输入格式：手机 168******88 固话 0106****8</p>
          </div>
        </div>
      </div>
     
    </el-dialog>
  </div>
</template>

<script>
import { mixins } from "@/mixin/index";
export default {
  mixins: [mixins],
  data() {
      return {
        phone:'',
        dialogVisible: false,
      };
    },
    methods: {
      handleClose() {
        this.phone = ''
        this.dialogVisible= false
      },
      open(){
        this.phone = ''
        this.dialogVisible= true
      }
    }
};
</script>

<style scoped lang="less">
/deep/.el-dialog{
  .el-dialog__header{
    padding: 0;
    
  }
  .el-dialog__body{
    padding: 0;
  }
}
.dialog_body{
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  .col_box{
    padding: 50px 0 40px;
    background: #0af;
    text-align: center;
    color: #fff;
    position: relative;
    h3{
      font-size: 30px;
      text-align: center;
    }
    p{
      font-size: 24px;
      text-align: center;
    }
    .close{
      position: absolute;
      top: 6px;
      right: 6px;
      font-size: 30px;
      cursor: pointer;
      opacity: 0.8;
    }
  }
  .inp_box{
    padding:20px;
    background: #fff;
    display: flex;
    input{
      width: 60%;
      height: 36px;
      padding: 0 10px;
      background: #fff;
      border: solid 1px #0af;
      font-size: 16px;
      outline: none;
      box-shadow: none;
    }
    a{
      width: 40%;
      text-align: center;
      height: 36px;
      line-height: 36px;
      background: #0af;
      color: #fff;
      user-select: none;
      text-decoration: none;
    }
  }
  .info_box{
    .line{
      padding: 10px 0;
      border-top: dashed 1px #ccc;
      p{
        text-align: center;
        color: #333;
        margin-bottom: 15px;
      }
    }
  }
}
</style>