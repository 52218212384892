<template>
  <div class="img_body">
    <img src="@/assets/images/product/p1.jpg" alt="" />
    <img src="@/assets/images/details/d1.jpg" alt="" />
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="less">
.img_body {
  padding: 10px 0 10px;
  overflow: hidden;
  min-width: 320px;
  margin: 0 auto;
  background: #fff;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>