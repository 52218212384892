import {swipeData,productTypeMap} from '@/utils/mockData'
import {isMobile,isPhone} from '@/utils/validate'
export const mixins = {
  data() {
    return {
      swipeData,
      productTypeMap,
      productName:'产品名称',
      number:0,//浏览人数
      residue:0,// 剩余人数
      timer:null, //定时器
      randomNo:3000,//定时器的时间
      year:'',//年
      month:'',//月
      day:'',//日
      active1:'', // 产品参数1 高亮
      active2:'',// 产品参数2 高亮
      active3:'',// 产品参数3 高亮
      formData:{},//页尾留言表单
      city:''
    };
  },
  computed: {},
  created() {

    this.initNumber() 
    this.getNowDate()
    this.getUserCity()
  },
  mounted() {
    
  },
  beforeDestroy(){
    if(this.timer){
      clearInterval(this.timer)
    }
  },
  methods: {
    initNumber(){ //初始化浏览人数
      if(this.timer){
        clearInterval(this.timer)
      }

      this.residue = Math.floor(Math.random()*19)+11
      this.number = Math.random()*(180-120)+120
      this.number = this.number.toFixed(0)
      this.timer = setInterval(() => {
        this.number++
        let n = Math.floor(Math.random()*6)+1
        this.randomNo = n*1000 //定时器的时间是随机的
      }, this.randomNo);
    },
    getNowDate(){
      let myDate = new Date();
      this.year =  myDate.getFullYear()
      this.month = myDate.getMonth()+1;
      this.day = myDate.getDate()
    },
    getUserCity(){ //获取用户城市信息
      this.$axios.post('/seo/customer/getCity',{
      }).then(res=>{
        this.city = res.data.result
      })
    },
    handleClick1(item){
      this.active1 = item.value
    },
    handleClick2(item){
      this.active2 = item.value
    },
    handleClick3(item){
      this.active3 = item.value
    },
    submitData(from){
      
      if(isPhone(from.cuPhone)||isMobile(from.cuPhone)){
        this.$axios.post('/seo/customer/add',{
          cuName:from.cuName||'',
          cuPhone:from.cuPhone||'',
          cuRemark:from.cuRemark||'',
        }).then(()=>{
          this.$message({
            message: '提交成功，稍后会有一对一客服与您联系',
            type: 'success'
          });
        })
      }else{
        this.$message({
          message: '手机号或者电话号码错误',
          type: 'warning'
        });
      }
      
    },


















  },
};