<template>
  <div class="header">
    <div class="m_head">
      <div class="logo">
        <div class="header_top">
          <a class="logo">
            <img src="@/assets/logo.png" alt="" />
          </a>
        </div>
      </div>
      <div class="slogan">
        "多年行业经验，专注设计生产施工，为您"
        <b>
          <a href="">免费报价</a>
        </b>
      </div>
    </div>

    <div style="position: relative">
      <div class="carousel">
        <el-carousel height="390px" arrow="always">
          <el-carousel-item v-for="item in 4" :key="item">
            <div class="slide_item">
              <img
                :src="require(`@/assets/images/product/p${item}.jpg`)"
                alt=""
              />
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>

      <div class="mes_news_list">
        <div>
          <div class="notice_box swiper" id="swiper_notice">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(item, index) in swipeData"
                :key="index"
              >
                <span style="color: #333">{{ item.city }}</span>
                <span style="color: #999"> {{ item.name }}</span>
                <span style="color: #d00"> {{ item.phone }}</span>
                <span style="color: #f90"> {{ item.info }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <h1 class="title">
        <span>木制凉亭多少钱</span>
        <span class="red">免费报价</span>
        <span class="red">供您对比</span>
      </h1>
      <ul class="tags">
        <li>专业团队</li>
        <li>经验丰富</li>
        <li>厂家直供</li>
        <li>源头低价</li>
      </ul>
      <div class="tit_qa">
        <div class="qa">
          <p>问：2023年9月木制凉亭多少钱价格/多少钱？</p>
          <p>答：因材料、运输等价格波动，请填写手机号获取今日报价表</p>
        </div>
        <div class="inp_tel">
          <input type="text" placeholder="请输入手机号" />
          <div class="btn">免费获取报价</div>
        </div>
        <p class="tip">报价单含各产品价格及参数,方便您做对比决策</p>
      </div>
    </div>

    <div class="act_box" id="searchBar">
      <p>
        [{{ month }}月{{ day }}日] 网络咨询免费报价,名额还剩:{{ residue }}名！
      </p>
      <p class="btn" @click="openDialog">立即获取名额</p>
    </div>
  </div>
</template>

<script>
import { mixins } from "@/mixin/index";
import Swiper from "swiper";
export default {
  mixins: [mixins],
  data() {
    return {
      swiperOption: {
        direction: "vertical", //滑动方向默认是水平
        slidesPerView: 2,
        loop: true, // 是否循环滚动
        //自动播放
        autoplay: {
          delay: 1000,
        },
      },
    };
  },
  created() {
    console.log(this.productName);
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.initSwiper();
  },
  methods: {
    handleScroll() {
      this.scrollTop = window.pageYOffset;
    },
    initSwiper() {
      var mySwiper = new Swiper("#swiper_notice", {
        autoplay: true, //可选选项，自动滑动
        loop: true, // 是否循环滚动
        direction: "vertical", //滑动方向默认是垂直
        slidesPerView: 2, //每次展示两条
      });
    },
    openDialog() {
      this.$emit("openDialog");
    },
  },
};
</script>

<style scoped lang="less">
.header {
  background-color: #fff;
}
.m_head {
  width: 100%;
  height: 40px;
  padding: 0 10px;
  background: #f8f8f8;
  border-bottom: solid 1px #f2f2f2;
  display: flex;
  align-items: center;
  .logo {
    height: 20px;
    display: flex;
    align-items: center;
    a,
    a:link,
    a:visited {
      -webkit-tap-highlight-color: transparent;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      color: rgba(0, 0, 0, 0.9);
      text-decoration: none;
    }
    img {
      height: 20px;
      max-width: 100%;
      border: 0;
      vertical-align: top;
      overflow-clip-margin: content-box;
      overflow: clip;
    }
  }
  .slogan {
    margin-left: 10px;
    padding-left: 10px;
    border-left: solid 1px #ddd;
    font-size: 13px;
    color: #666;
    font-weight: lighter;
    b {
      font-weight: normal;
      a,
      a:link,
      a:visited {
        -webkit-tap-highlight-color: transparent;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
      }
      a {
        color: #f30 !important;
        margin-left: 5px;
        text-decoration: none;
      }
    }
  }
}
.carousel {
  overflow: hidden;
  .slide_item {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.mes_news_list {
  width: 80%;
  margin: 0;
  position: absolute;
  left: 12px;
  bottom: 32px;
  z-index: 5;
  overflow: hidden;
  height: 66px;
  div {
    position: relative;
  }
  .notice_box {
    margin-top: 10px;
    height: 60px;
    overflow: hidden;
    /deep/.swiper-slide {
      height: 24px !important;
      font-size: 14px;
      line-height: 24px;
      margin: 0px 0 6px;
      padding: 0 10px;
      background: rgba(255, 255, 255, 0.8);
      border-radius: 16px;
      opacity: 0.7;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
      word-break: break-all;
      white-space: normal !important;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
  }
}
.card {
  position: relative;
  z-index: 999;
  top: -24px;
  margin: 0 auto;
  width: 90%;
  height: 198px;
  background: #fff;
  border: solid #e6e6e6;
  border-width: 1px;
  border-radius: 10px;
  .title {
    padding: 8px 10px 6px;
    font-size: 20px;
    font-weight: 600;
    span {
      margin-right: 5px;
    }
    .red {
      color: #f30;
    }
  }
  .tags {
    padding: 0 10px 11px;
    display: flex;
    li {
      color: #f44;
      padding: 4px 6px;
      border: 1px solid #f44;
      border-radius: 2px;
      font-size: 12px;
      font-weight: bold;
      margin-right: 6px;
    }
  }
  .tit_qa {
    padding: 8px 10px 10px;
    background: #346;
    color: #fff;
    display: block;
    border-radius: 0 0 10px 10px;
    .tip {
      margin-top: 5px;
      font-size: smaller !important;
      color: #ccc !important;
    }
    .qa {
      p {
        margin: 5px 0;
        font-size: 12px;
      }
    }
    .inp_tel {
      display: flex;
      padding: 5px 0 0;
      input {
        width: 68%;
        height: 32px;
        padding: 0 5px;
        border: solid 1px #0af;
        float: left;
        color: #222;
        font-size: 14px;
        outline: none;
        font-family: "microsoft yahei", Verdana, Arial, Helvetica, sans-serif;
        box-shadow: none;
      }
      input::placeholder {
        /* placeholder颜色  */
        color: #bbbbbb;
      }
      .btn {
        width: 32%;
        line-height: 32px;
        background: #0af;
        color: #fff;
        text-align: center;
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
}
.act_box {
  display: flex;
  justify-content: space-between;
  margin-top: -24px;
  padding: 10px 12px;
  background: none;
  color: #f90;
  font-size: 12px;
  .btn {
    color: #07f;
    float: right;
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
  }
}
</style>