<template>
  <div class="content">
    <div class="header" v-if="scrollTop<60">
      <div class="logo"><img src="@/assets/logo.png" alt="" /></div>
      <div class="pageName">公司名称</div>
      <div class="info">
        多年行业经验，专业设计生产施工，为您<span style="color:#f44">免费报价</span>
      </div>
      <em style="flex:1"></em>
      <div style="color: #666;">咨询热线：400-888-999</div>
    </div>
    <div class="top_scroll" v-else>
      <div class="header_top">
        <div class="logo"><img src="@/assets/logo.png" alt="" /></div>
        <div class="info">2023年9月 <span style="color:#f44">产品名称</span> 厂家直供 广大客户共同的选择 一次合作 终生信赖 包安装运输</div>
        <em style="flex:1"></em>
        <div class="btn" @click="openDialog">立即询价</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mixins } from "@/mixin/index";
export default {
  mixins: [mixins],
  data() {
        return {
          scrollTop:0,

        };
    },
    created() {
      console.log(this.productName);
    },
    mounted(){
      window.addEventListener('scroll', this.handleScroll)
    },
    methods:{
      handleScroll(){
        this.scrollTop = window.pageYOffset
      },
      openDialog(){
        this.$emit('openDialog')
      },
    }
};
</script>

<style scoped lang="less">
.content{
  width: 100%;
  background: #fff;
  position: relative;
  min-height: 50px;
  .header{
    width: 1190px;
    height: 80px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    .logo{
      width: 40px;
      height: 40px;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .pageName{
      font-size: 22px;
      font-weight: bold;
      margin-left: 6px;
      padding-right: 10px;
      color: #006;
      border-right: 1px solid #ccc;
    }
    .info{
      color: #666;
      margin-left: 10px;
      font-size: 18px;
    }
  }
  .top_scroll{
    width: 100%;
    height: 50px;
    position: fixed;
    left: 0%;
    top: 0;
    background: #fff;
    z-index: 9;
  .header_top{
    width: 1190px;
    height: 50px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    .logo{
      width: 26px;
      height: 26px;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .info{
      font-size: 18px;
      font-weight: 600;
    }
    .btn{
      font-size: 12px;
      background: #f44;
      text-align: center;
      height: 32px;
      line-height: 32px;
      padding: 0 15px;
      border-radius: 16px;
      color: #fff;
      font-weight: 600;
      user-select: none;
      cursor: pointer;
    }
  }
  }

}
</style>