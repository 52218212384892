import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import VueRouter from "vue-router";
import Router from './router'
import request from './utils/request'
Vue.prototype.$request = request;
import {
  app
} from './global/global.js'

import 'swiper/dist/css/swiper.min.css';
import 'element-ui/lib/theme-chalk/index.css';
import '../src/assets/iconfont.css'
import '../src/assets/rebuild.css'
import axios from 'axios'
Vue.prototype.$axios = axios
import $ from 'jquery'
Vue.prototype.$ = $
// 公共样式
import "./assets/styles/common.css";
import VueLazyLoad from 'vue-lazyload'
Vue.use(VueLazyLoad, {
  preLoad: 1,
  error: require('./assets/balance.jpg'),
  loading: require('./assets/balance.jpg'),
  attempt: 2,
})

Vue.config.productionTip = false

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(ElementUI).use(VueRouter).use(app)

new Vue({
  render: h => h(App),
  router: Router,
}).$mount('#app')