import VueRouter from "vue-router";

let originPush = VueRouter.prototype.push;
let originReplace = VueRouter.prototype.replace;
VueRouter.prototype.push = function (location, resolve, reject) {
    if (resolve && reject) {
        originPush.call(this, location, resolve, reject);
    } else {
        originPush.call(this, location, () => {}, () => {});
    }
}
VueRouter.prototype.replace = function (location, resolve, reject) {
    if (resolve && reject) {
        originReplace.call(this, location, resolve, reject);
    } else {
        originReplace.call(this, location, () => {}, () => {});
    }
}

import PcHome from '@/pages/pc_home'
import MIHome from '@/pages/m_home'

const router = new VueRouter({
    routes: [{
            path: '',
            redirect: '/pc_home'
        },
        {
            path: "/pc_home", // pc端首页
            name: PcHome,
            component: PcHome
        },
        {
            path: '/m_home', // 手机端首页
            name: MIHome,
            component: MIHome
        }
    ]
})

export default router;