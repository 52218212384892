<template>
  <div class="foot_content">
    <div class="page_ad">
      <div class="page_box">
        <div class="p1">
          <span style="color: #f44">
            [{{ month }}月{{ day }}日] 网络咨询免费报价,名额还剩:{{
              residue
            }}名！</span
          >
        </div>
        <p>
          <a href="" class="btn">立即获取名额</a>
          <a href="" class="btn">有问题不清楚？咨询客服</a>
        </p>
      </div>
    </div>
    <div class="qa_mes">
      <div class="left">
        <em>quick quotes in the seconds</em>
        <div class="tit">
          <h3>三分钟极速报价</h3>
          <p>工程师一对一免费选型咨询</p>
        </div>
        <div class="label">
          <span>报价清晰</span>
          <span>材料透明</span>
          <span>省钱省心</span>
        </div>
        <div class="tel">
          <img src="@/assets/qa_mestel.png" alt="" />
          <a href="">获取出厂配置报价单</a>
        </div>
      </div>
      <div class="right">
        <div class="tit_qa">
          <div class="qa">
            <p>问：2023年9月木制凉亭多少钱价格/多少钱？</p>
            <p>答：因材料、运输等价格波动，请填写手机号获取今日报价表</p>
          </div>
          <div class="inp_tel">
            <input type="text" placeholder="请输入手机号" />
            <div class="btn">免费获取报价</div>
          </div>
          <p class="tip">报价单含各产品价格及参数,方便您做对比决策</p>
        </div>
      </div>
    </div>
    <div class="page_mes">
      <div class="main">
        <div class="page_zz"></div>
        <div class="info_box">
          <h6>源头低价 获取出厂配置报价单</h6>
          <p>3分钟急速报价，您将收到回电，请保持电话畅通</p>
          <div class="card_box">
            <div class="item">
              <span>厂家直供</span>
              <span>质量保障</span>
            </div>
            <div class="item">
              <span>选型工程师</span>
              <span>一对一服务</span>
            </div>
            <div class="item">
              <span>现货直发</span>
              <span>包安装验收</span>
            </div>
          </div>
          <div class="info">
            今日已有<span style="color: #ff0"> {{ number }}</span> 人浏览咨询
          </div>
          <div class="info"><a href="">免费咨询客服</a> ，3分钟急速报价</div>
        </div>
        <div class="form_box">
          <div class="form_content">
            <div class="line">
              <i class="icon name"></i>
              <el-input
                class="border_none"
                placeholder="您的称呼"
                v-model="formData.cuName"
              ></el-input>
              <el-radio-group
                v-model="sex"
                class="tags"
                size="mini"
                fill="#e33"
              >
                <el-radio-button class="item" label="先生"></el-radio-button>
                <el-radio-button class="item" label="女士"></el-radio-button>
              </el-radio-group>
            </div>
            <div class="line">
              <i class="icon tel"></i>
              <el-input
                class="border_none"
                placeholder="输入您的手机号"
                v-model="formData.cuPhone"
              ></el-input>
            </div>
            <el-input
              class="border_none"
              type="textarea"
              v-model="formData.cuRemark"
              :rows="4"
              placeholder="详细需求（选填），如您关心的日常运维成本，产品价格，质量，外观，售后服务要求等等..."
            ></el-input>
            <div class="line1"></div>
            <a class="btn" href="javascript:;" @click="submitData"
              >提交需求，免费获取报价单</a
            >
            <div class="xy">
              <span>获取报价前请先阅读<span>《个人信息保护声明》</span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mixins } from "@/mixin/index";
export default {
  mixins: [mixins],
  data() {
    return {
      sex: "",
    };
  },
};
</script>

<style scoped lang="less">
.page_ad {
  padding: 5px;
  width: 100%;
  background: url(../../../assets/pro_hd_bg.png) center;
  background-color: #eeeccc;
  color: #d00;
  .page_box {
    padding: 10px 10px 5px;
    width: 100%;
    border: solid 1px #d00;
    text-align: center;
    .p1 {
      line-height: 20px;
      margin-bottom: 8px;
      font-size: 14px;
      font-weight: normal;
    }
    .btn {
      line-height: 20px;
      margin: 0 5px 5px;
      padding: 6px 15px;
      background: #d00;
      color: #f7f07d;
      display: inline-block;
      vertical-align: top;
      font-size: 14px;
      -webkit-tap-highlight-color: transparent;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      text-decoration: none;
    }
  }
}
.qa_mes {
  background: #fff;
  .left {
    padding: 30px 0 20px 30px;
    em {
      font-size: 12px;
      font-style: normal;
      color: #987;
      text-transform: uppercase;
    }
    .tit {
      line-height: 30px;
      font-size: 20px;
      margin: 5px 0 10px;
      font-weight: normal;
      color: #d00;
    }
    .label {
      margin-bottom: 10px;
      span {
        line-height: 25px;
        font-size: 12px;
        margin: 3px 6px 3px 0;
        padding: 0 20px;
        background: #fff;
        border: solid 1px #987;
        color: #765;
        display: inline-block;
      }
    }
    .tel {
      font-size: 20px;
      line-height: 36px;
      color: #d00;
      img {
        height: 24px;
        margin: 6px 10px 0 0;
        max-width: 100%;
        border: 0;
        vertical-align: top;
      }
      a {
        color: #d00;
        -webkit-tap-highlight-color: transparent;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        text-decoration: none;
      }
    }
  }
  .right {
    padding: 20px 20px 15px;
    background: #f8f8f8;
    .qa {
      line-height: 1.5em;
      font-size: 12px;
      color: #0ae;
      p {
        margin-bottom: 5px;
      }
    }
    .inp_tel {
      display: flex;
      padding: 5px 0 0;
      input {
        width: 45%;
        height: 32px;
        padding: 0 10px;
        border: solid 1px #0af;
        float: left;
        color: #222;
        font-size: 13px;
        outline: none;
        font-family: "microsoft yahei", Verdana, Arial, Helvetica, sans-serif;
        box-shadow: none;
      }

      input::placeholder {
        /* placeholder颜色  */
        color: #bbbbbb;
      }
      .btn {
        width: 32%;
        line-height: 32px;
        background: #0af;
        color: #fff;
        text-align: center;
        font-size: 13px;
        cursor: pointer;
      }
    }
    .tip {
      font-size: smaller;
      opacity: 0.8;
      color: #987;
      margin-top: 8px;
    }
  }
}
.page_mes {
  margin: 0px 0;
  position: relative;
  box-sizing: border-box;
  min-width: 320px;
  margin: 0 auto;
  color: rgba(0, 0, 0, 0.9);
  -webkit-text-size-adjust: none;
  outline: none;
  box-shadow: none;
  font-size: 12px;
  .main {
    width: 100%;
    padding: 20px 0;
    background: rgba(204, 51, 51, 0.9);
    border-radius: 5px;
    position: relative;
    margin: 0 auto;
    box-sizing: border-box;
    // .page_zz {
    //   width: 100%;
    //   height: 100%;
    //   background-size: cover;
    //   position: absolute;
    //   left: 0;
    //   top: 0;
    //   z-index: -1;
    //   opacity: 0.5;
    // }
    .info_box {
      width: 100%;
      margin-left: 0%;
      text-align: center;
      color: #fff;
      margin-bottom: 18px;
      h6 {
        font-size: 24px;
        font-weight: normal;
        line-height: 1.5em;
      }
      p {
        font-size: 16px;
        opacity: 0.8;
        line-height: 1.5em;
        margin-bottom: 20px;
      }
      .card_box {
        margin-bottom: 10px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .item {
          width: 25%;
          padding: 10px 0px;
          font-size: 13px;
          line-height: 1.8em;
          border: solid 1px #fff;
          font-weight: bold;
          color: #fff;
          text-align: center;
          position: relative;
          span {
            text-align: center;
            display: block;
          }
          &::after {
            content: "";
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.1);
            position: absolute;
            left: -6px;
            top: -6px;
            z-index: -1;
          }
        }
      }
      .info {
        font-size: 16px;
        line-height: 1.6em;
        color: #fff;
        text-align: center;
        a {
          color: #fff;
          text-decoration: underline;
        }
      }
    }
    .form_box {
      width: 96%;
      margin: 0 auto;
      background: #fff;
      border-radius: 5px;
      padding-top: 6px;
      .form_content {
        width: 96%;
        margin: 0 auto;
        .border_none /deep/ .el-input__inner {
          border: none;
          height: 32px;
          font-size: 13px;
        }
        .border_none /deep/ .el-textarea__inner {
          border: none;
          padding: 5px;
          resize:none;
        }
        .line1 {
          border: none; /* 去掉默认边框 */
          border-top: 1px solid #e2e2e2;
          padding-bottom: 6px;
        }
        .line {
          display: flex;
          margin: 0;
          padding: 8px 0px 5px;
          border-bottom: solid 1px #e2e2e2;
          position: relative;
          .name {
            background-position: -60px -15px;
            background-size: 150px;
          }
          .tel {
            background-position: -82.5px -15px;
            background-size: 150px;
          }
          i {
            position: absolute;
            top: 13px;
            left: 13px;
            width: 18px;
            height: 18px;
            opacity: 0.6;
            line-height: 0;
            background: url(../../../assets/icon.png) no-repeat;
            font-size: 0;
            text-indent: -99999px;
            display: inline-block;
            vertical-align: top;
          }
          .border_none {
            padding-left: 38px;
            height: 32px;
            font-size: 13px;
          }
          .tags {
            display: flex;
            .item {
              margin-right: 6px;
              /deep/ .el-radio-button__inner {
                border-radius: 4px;
                border: 1px solid #dcdfe6;
                box-shadow: none;
              }
            }
          }
        }
        .btn {
          display: block;
          background: #c33;
          width: 100%;
          line-height: 40px;
          border-radius: 2px;
          padding: 0;
          color: #fff;
          text-align: center;
          font-size: 16px;
          opacity: 0.9;
          user-select: none;
          text-decoration: none;
        }
        .xy {
          font-size: smaller;
          margin: 10px 0;
          color: #000;
          opacity: 0.5;
          padding-bottom: 10px;
        }
      }
    }
  }
}
</style>